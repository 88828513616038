<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        Terminal Request - Key Accounts
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <p class="text-red-800" v-if="errorMssg">
            {{ errorMssg }}
          </p>
        </div>

        <div class="w-full xl:my-4 xl:px-4 mt-5">
          <div
            class="md:w-1/2 p-5 border-2 border-gray-100 bg-white rounded-lg"
          >
            <!-- <div> -->
            <form
              @submit.prevent="newTerminalRequestForm"
              class="w-full max-w-full"
            >
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Merchant Email <span class="text-red-800">*</span>
                  </p>
                  <div
                    class="max-h-64 h-auto max-w-sm w-full overflow-y-auto bg-white mt-1 mr-1 mb-2 rounded-md"
                  >
                    <input
                      type="email"
                      class="shadow appearance-none rounded py-2 w-3/4 leading-5 px-3 bg-gray-200 border border-gray-200 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                      name=""
                      placeholder="Enter Email"
                      id="merchantEmail"
                      v-model="merchantEmail"
                      required
                    />
                    <button
                      @click="handleBusinessEmailSearch"
                      class="uppercase shadow bg-blue-800 hover:bg-blue-700 w-1/4 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Business Name <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      name=""
                      placeholder="Enter Business Name"
                      id="businessName"
                      v-model="businessName"
                      required
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Delivery address <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Delivery address"
                      id="deliveryAddress"
                      v-model="deliveryAddress"
                      required
                    />
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    City <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter City"
                      id="deliveryCity"
                      v-model="deliveryCity"
                      required
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    State <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Delivery State"
                      id="deliveryState"
                      v-model="deliveryState"
                      required
                    >
                      <option value="">Select state</option>
                      <option
                        :value="state"
                        v-for="(state, i) in states"
                        :key="i"
                      >
                        {{ state }}
                      </option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Local Government <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter LGA"
                      id="deliveryLGA"
                      v-model="deliveryLGA"
                      required
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Phone number of merchant
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="number"
                      class="tel appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Phone number of merchant"
                      id="merchantPhoneNumber"
                      v-model="merchantPhoneNumber"
                      required
                    />
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Terminal type
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="terminalType"
                      id="terminalType"
                      required
                    >
                      <option value="">Select terminal type</option>
                      <option value="MPOS">MPos</option>
                      <option value="LINUX">Linux</option>
                      <option value="ANDROID">Android</option>
                    </select>
                  </p>
                </div>
              </div>
              <div
                class="flex flex-wrap -mx-3 mb-6"
                v-if="terminalType === 'MPOS'"
              >
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Accessories Name
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="accessoryName"
                      id="accessoryName"
                      required
                    >
                      <option value="">Select terminal type</option>
                      <option value="Bluetooth Printer">
                        Bluetooth Printer
                      </option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Number of Accessories requested
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="number"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Numbers of Terminals requested"
                      id="accessoryQuantity"
                      v-model="accessoryQuantity"
                      required
                      min="1"
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Number of terminals requested
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="number"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Numbers of Terminals requested"
                      id="numberofTerminals"
                      v-model="numberofTerminals"
                      required
                      min="1"
                    />
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Requested date of delivery
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="date"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Requested date of delivery"
                      id="requestedDateofDelivery"
                      v-model="requestedDateofDelivery"
                      required
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Mark as loan? (Yes or No)
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      id="markAsLoan"
                      v-model="markAsLoan"
                      required
                    >
                      <option value="">----</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">If Yes - Loan ID</p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Loan ID"
                      id="loanID"
                      v-model="loanID"
                      :readonly="
                        markAsLoan === 'no' || markAsLoan === '' ? true : false
                      "
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Key Account Officer's name
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Key Account Officer's name"
                      id="keyAccountOfficer"
                      v-model="keyAccountOfficer"
                    />
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Terminal pricing
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="terminalPricing"
                      id="terminalPricing"
                      required
                    >
                      <option value="">Select terminal pricing</option>
                      <option value="outrightPurchase">Outright</option>
                      <option value="leaseToOwn">Lease to own</option>
                      <option value="keyAccount">Key Account</option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Corporate Sales Vertical
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="selectCorperateSalesVertical"
                      id="terminalPricing"
                      required
                    >
                      <option value="">Select</option>
                      <option
                        :value="c"
                        :key="i"
                        v-for="(c, i) in corperateSalesVertical"
                        >{{ c }}</option
                      >
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">
                    Merchant's Expected Monthly TPV
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="merchantTPV"
                      id="terminalPricing"
                      required
                    >
                      <option value="">Select </option>
                      <option
                        :value="m"
                        :key="i"
                        v-for="(m, i) in merchantExpectedMonthlyTPV"
                        >{{ m }}</option
                      >
                    </select>
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Preferred Network Provider
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="preferredNetworkProvider"
                      id="preferredNetworkProvider"
                      required
                    >
                      <option value="" disabled>
                        Select network provider
                      </option>
                      <option
                        :value="network"
                        v-for="(network, i) in preferredNetworkProviders"
                        :key="i"
                      >
                        {{ network }}
                      </option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <p class="text-xs font-bold mb-3">Support Officer Email</p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Support Officer Email"
                      id="supportOfficerEmail"
                      v-model="supportOfficerEmail"
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    KYC Done
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="kycDone"
                      id="kycDone"
                      required
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option :value="true">
                        Yes
                      </option>
                      <option :value="false">
                        No
                      </option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    POS Request Type
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="requestType"
                      id="kycDone"
                      required
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="new">
                        New POS Deployment Request
                      </option>
                      <option value="existing">
                        Additional POS Deployment Request
                      </option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="w-full flex mt-10">
                <div class="m-3 w-80">
                  <button
                    class="py-3 mr-5 px-10 bg-yellow-300 rounded-md font-semibold text-black text-sm"
                    type="reset"
                  >
                    Reset
                  </button>
                  <button
                    class="py-3 px-10 bg-blue-800 rounded-md font-semibold text-white text-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <!-- </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
// import mixpanel from "mixpanel-browser";
// import { GET_USER_DATA } from "../browser-db-config/localStorage";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      errorMssg: "",
      payload: {},
      businessName: "",
      merchantEmail: "",
      terminalPricing: "",
      numberofTerminals: "",
      merchantPhoneNumber: "",
      requestedDateofDelivery: "",
      supportOfficerEmail: "",
      deliveryAddress: "",
      deliveryCity: "",
      deliveryState: "",
      deliveryLGA: "",
      markAsLoan: "",
      loanID: "",
      keyAccountOfficer: "",
      terminalType: "",
      overrideCode: "",
      preferredNetworkProvider: "",
      selectCorperateSalesVertical: "",
      merchantTPV: "",
      kycDone: "",
      requestType: "",
      merchantExpectedMonthlyTPV: [
        "N100 million and above",
        "Between ₦50 Million - ₦100 Million",
        "Between ₦20 Million - ₦49 Million",
        "Between ₦10 Million - ₦19 Million",
        "Between ₦1 Million - ₦9 Million",
        "Less than ₦1 Million",
      ],
      corperateSalesVertical: [
        "Electronics",
        "Fashion, Beauty and lifestyle",
        "Food Retail",
        "General Retail",
        "Healthcare and Hospitality",
      ],
      states: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ],
      pricings: {
        outrightPurchase: "5000",
        leaseToOwn: "1000",
        keyAccount: "2000",
      },
      preferredNetworkProviders: ["9mobile", "MTN", "GLO", "AIRTEL"],
      accessoryName: "",
      accessoryQuantity: "",
    };
  },
  async mounted() {
    // mixpanel.init("18643d563023caee796b6682e5eada6c", {
    //   debug: true,
    //   track_pageview: true,
    // });
    // mixpanel.identify(GET_USER_DATA().name);
  },
  methods: {
    async handleBusinessEmailSearch() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "FETCH_MERCHANT_USING_NAME_OR_EMAIL",
          { search: this.merchantEmail }
        );
        this.isLoading = false;

        if (res.status === true) {
          this.businessName = res.data.items[0].business_name;
          this.merchantPhoneNumber = res.data.items[0].phone_number;
          this.deliveryAddress = res.data.items[0].business_address;
          this.kycDone = res.data.items[0].kyc_status;

          //Track Event on MixPanel
          // mixpanel.track("Fetch Business Details on Key Accounts Form", {
          //   "Fetch Type": "Business Details",
          // });
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async newTerminalRequestForm() {
      const d = new Date();
      const todayDate = new Date(d.setDate(d.getDate())).toJSON().split("T")[0];
      console.log(this.requestedDateofDelivery);
      console.log(todayDate);
      console.log(todayDate === this.requestedDateofDelivery);
      if (this.requestedDateofDelivery >= todayDate) {
        const response = confirm("Are you sure you want to submit?");
        if (response) {
          this.payload.businessName = this.businessName;
          this.payload.businessOwner = this.businessName;
          this.payload.email = this.merchantEmail;
          this.payload.terminalPrice = {
            terminalType: this.terminalType,
            [this.terminalPricing]: this.pricings[this.terminalPricing],
          };
          this.payload.preferredNetworkProvider = this.preferredNetworkProvider;
          this.payload.quantity = parseInt(this.numberofTerminals, 10);
          this.payload.deliveryPhoneNumber = this.merchantPhoneNumber;
          this.payload.deliveryDate = this.requestedDateofDelivery;
          this.payload.deliveryAddress = this.deliveryAddress;
          this.payload.deliveryCity = this.deliveryCity;
          this.payload.deliveryState = this.deliveryState;
          this.payload.deliverylga = this.deliveryLGA;
          this.payload.isLoan = this.markAsLoan == "yes" ? true : false;
          this.payload.loanID = this.loanID;
          this.payload.agentName = this.keyAccountOfficer;
          this.payload.source = "KeyAccounts";
          this.payload.businessType = this.selectCorperateSalesVertical;
          this.payload.merchantTPV = this.merchantTPV;
          this.payload.kycDone = this.kycDone;
          this.payload.requestType = this.requestType;

          // this.payload.overrideCode = this.overrideCode;
          let response_message = "";
          if (this.terminalType === "MPOS") {
            this.payload.accessoryRequest = [
              {
                accessoriesPrice: {
                  accessoriesType: this.accessoryName,
                },
                quantity: parseInt(this.accessoryQuantity, 10),
              },
            ];
          }

          this.isLoading = true;
          try {
            let res = await this.$store.dispatch(
              "CREATE_NEW_REQUEST_FORM",
              this.payload
            );

            if (res.status === true) {
              this.isLoading = false;
              response_message += res.message;
              alert(response_message);
              this.businessName = "";
              this.businessName = "";
              this.merchantEmail = "";
              this.terminalPricing = "";
              this.numberofTerminals = "";
              this.merchantPhoneNumber = "";
              this.requestedDateofDelivery = "";
              this.deliveryAddress = "";
              this.deliveryCity = "";
              this.deliveryState = "";
              this.deliveryLGA = "";
              this.markAsLoan = "";
              this.loanID = "";
              this.keyAccountOfficer = "";
              this.accessoryName = "";
              this.accessoryQuantity = "";
              this.preferredNetworkProvider = "";
              this.terminalType = "";
              this.preferredNetworkProvider = "";
              this.selectCorperateSalesVertical = "";
              this.merchantTPV = "";
              this.kycDone = "";
              this.requestType = "";
            } else {
              alert(res.message);
              this.isLoading = false;
            }
          } catch (error) {
            console.log(error);
          }

          this.isLoading = false;
        }
      } else {
        alert(
          `Please choose a date greater or equal to today's date (${todayDate})`
        );
      }
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
